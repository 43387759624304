.CompHeading-Container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    padding-bottom: 52px;
}

.CompHeading-Container h1 {
    text-align: center;
    color: var(--color-aixioom-blue-main);
}

.CompHeading-Container h3 {
    text-align: center;
    color: var(--color-aixioom-grey-text);
}

@media(min-width: 1501px) {
    .CompHeading-Container {
        gap: var(--gap-aixioom-large-element);
    }
    
    .CompHeading-Container h1 {
        font-size: var(--font-size-aixioom-large-h1);
        font-style: var(--font-style-aixioom-large-h1);
        font-weight: var(--font-weight-aixioom-large-h1);
        line-height: var(--line-height-aixioom-large-h1);
        letter-spacing: var(--letter-spacing-aixioom-large-h1);
        margin: var(--margin-aixioom-large-h1);
    }
    
    .CompHeading-Container h3 {
        font-size: var(--font-size-aixioom-large-h3);
        font-style: var(--font-style-aixioom-large-h3);
        font-weight: var(--font-weight-aixioom-large-h3);
        line-height: var(--line-height-aixioom-large-h3);
        letter-spacing: var(--letter-spacing-aixioom-large-h3);
        margin: var(--margin-aixioom-large-h3);
    }
}
@media(max-width: 1500px) {
    .CompHeading-Container {
        gap: var(--gap-aixioom-medium-element);
    }
    
    .CompHeading-Container h1 {
        font-size: var(--font-size-aixioom-medium-h1);
        font-style: var(--font-style-aixioom-medium-h1);
        font-weight: var(--font-weight-aixioom-medium-h1);
        line-height: var(--line-height-aixioom-medium-h1);
        letter-spacing: var(--letter-spacing-aixioom-medium-h1);
        margin: var(--margin-aixioom-medium-h1);
    }
    
    .CompHeading-Container h3 {
        font-size: var(--font-size-aixioom-medium-h3);
        font-style: var(--font-style-aixioom-medium-h3);
        font-weight: var(--font-weight-aixioom-medium-h3);
        line-height: var(--line-height-aixioom-medium-h3);
        letter-spacing: var(--letter-spacing-aixioom-medium-h3);
        margin: var(--margin-aixioom-medium-h3);
    }
}
@media(max-width: 550px) {
    .CompHeading-Container {
        gap: var(--gap-aixioom-small-element);
    }
    
    .CompHeading-Container h1 {
        font-size: 24px; /* changed to fit container and to avoid word break */
        font-style: var(--font-style-aixioom-small-h1);
        font-weight: var(--font-weight-aixioom-small-h1);
        line-height: var(--line-height-aixioom-small-h1);
        letter-spacing: var(--letter-spacing-aixioom-small-h1);
        margin: var(--margin-aixioom-small-h1);
    }
    
    .CompHeading-Container h3 {
        font-size: var(--font-size-aixioom-small-h3);
        font-style: var(--font-style-aixioom-small-h3);
        font-weight: var(--font-weight-aixioom-small-h3);
        line-height: var(--line-height-aixioom-small-h3);
        letter-spacing: var(--letter-spacing-aixioom-small-h3);
        margin: var(--margin-aixioom-small-h3);
    }
}