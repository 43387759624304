.CompIconText-Container, .CompIconText-Container .fade-in-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
}

.CompIconText-Container-Icon {
    display: flex;
    align-items: center;
    align-self: stretch;
}

.CompIconText-Container h3 {
    text-align: left;
    color: var(--color-aixioom-blue-main);
    align-self: stretch;
}

.CompIconText-Container p {
    color: var(--color-aixioom-grey-text);
    align-self: stretch;
}

@media(min-width: 1501px) {
    .CompIconText-Container {
        gap: var(--gap-aixioom-large-element);
    }
    
    .CompIconText-Container-Icon {
        padding: 10px 10px 10px 0;
        gap: 10px;
    }
    .CompIconText-Container-Icon img {
        height: 70px;
    }
    
    .CompIconText-Container h3 {
        font-size: var(--font-size-aixioom-large-h3);
        font-style: var(--font-style-aixioom-large-h3);
        font-weight: var(--font-weight-aixioom-large-h3);
        line-height: var(--line-height-aixioom-large-h3);
        letter-spacing: var(--letter-spacing-aixioom-large-h3);
        margin: var(--margin-aixioom-large-h3);
    }
    
    .CompIconText-Container p {
        font-size: var(--font-size-aixioom-large-text);
        font-style: var(--font-style-aixioom-large-text);
        font-weight: var(--font-weight-aixioom-large-text);
        line-height: var(--line-height-aixioom-large-text);
    } 
}
@media(max-width: 1500px) {
    .CompIconText-Container {
        gap: var(--gap-aixioom-medium-element);
    }
    
    .CompIconText-Container-Icon {
        padding: 10px 10px 10px 0;
        gap: 10px;
    }
    .CompIconText-Container-Icon img {
        height: 70px;
    }
    
    .CompIconText-Container h3 {
        font-size: var(--font-size-aixioom-medium-h3);
        font-style: var(--font-style-aixioom-medium-h3);
        font-weight: var(--font-weight-aixioom-medium-h3);
        line-height: var(--line-height-aixioom-medium-h3);
        letter-spacing: var(--letter-spacing-aixioom-medium-h3);
        margin: var(--margin-aixioom-medium-h3);
    }
    
    .CompIconText-Container p {
        font-size: var(--font-size-aixioom-medium-text);
        font-style: var(--font-style-aixioom-medium-text);
        font-weight: var(--font-weight-aixioom-medium-text);
        line-height: var(--line-height-aixioom-medium-text); /* 24px */
    } 
}

@media(max-width: 550px) {
    .CompIconText-Container {
        gap: 0px;
    }
    
    .CompIconText-Container-Icon {
        padding: 10px 10px 10px 0;
        gap: 10px;
    }
    .CompIconText-Container-Icon img {
        height: 50px;
    }
    
    .CompIconText-Container h3 {
        font-size: var(--font-size-aixioom-small-h3);
        font-style: var(--font-style-aixioom-small-h3);
        font-weight: var(--font-weight-aixioom-small-h3);
        line-height: var(--line-height-aixioom-small-h3);
        letter-spacing: var(--letter-spacing-aixioom-small-h3);
        margin: var(--margin-aixioom-small-h3);
    }
    
    .CompIconText-Container p {
        font-size: var(--font-size-aixioom-small-text);
        font-style: var(--font-style-aixioom-small-text);
        font-weight: var(--font-weight-aixioom-small-text);
        line-height: var(--line-height-aixioom-small-text);
    } 
}