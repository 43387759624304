.CompMap-Container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
}
.CompMap-Container h2 {
    text-align: center;
    color: var(--color-aixioom-blue-main);
}

.CompMap-Container img {
    width: 566px;
}

@media(min-width: 1501px) {
    .CompMap-Container {
        gap: 54px;
        padding-bottom: 52px;
    }
    .CompMap-Container h2 {
        font-size: var(--font-size-aixioom-large-h2);
        font-style: var(--font-style-aixioom-large-h2);
        font-weight: var(--font-weight-aixioom-large-h2);
        line-height: var(--line-height-aixioom-large-h2);
        letter-spacing: var(--letter-spacing-aixioom-large-h2);
        margin: var(--margin-aixioom-large-h2);
    }
    
    .CompMap-Container img {
        width: 566px;
    }
}
@media(max-width: 1500px) {
    .CompMap-Container {
        gap: 54px;
        padding-bottom: 52px;
        width: 100%;
    }
    .CompMap-Container h2 {
        font-size: var(--font-size-aixioom-medium-h2);
        font-style: var(--font-style-aixioom-medium-h2);
        font-weight: var(--font-weight-aixioom-medium-h2);
        line-height: var(--line-height-aixioom-medium-h2);
        letter-spacing: var(--letter-spacing-aixioom-medium-h2);
        margin: var(--margin-aixioom-medium-h2);
    }
    
    .CompMap-Container img {
        width: 80%;
    }
}
@media(max-width: 550px) {
    .CompMap-Container {
        gap: 54px;
        padding-bottom: 52px;
    }
    .CompMap-Container h2 {
        font-size: var(--font-size-aixioom-small-h2);
        font-style: var(--font-style-aixioom-small-h2);
        font-weight: var(--font-weight-aixioom-small-h2);
        line-height: var(--line-height-aixioom-small-h2);
        letter-spacing: var(--letter-spacing-aixioom-small-h2);
        margin: var(--margin-aixioom-small-h2);
    }
}