/* ________________Variables_________________ */
:root {
  /* ________________Colors____________________ */
  --color-aixioom-green-main: #05F29B;
  --color-aixioom-green-2: #07DBCA;
  --color-aixioom-blue-main: #1B1E59;
  --color-aixioom-blue-main-transparent: rgba(27, 30, 89, 0.4);
  --color-aixioom-blue-2: #1A4666;
  --color-aixioom-blue-3: #1169A3;
  --color-aixioom-blue-4: #07CCF9;
  --color-aixioom-main-red: #F2441D;
  --color-aixioom-grey-1: #E5E5E9;
  --color-aixioom-grey-2: #D9D9D9;
  --color-aixioom-grey-2-transparent: rgba(0, 0, 0, 0.05);
  --color-aixioom-grey-3: #CCCCD4;
  --color-aixioom-grey-background: #F7F7F7;
  --color-aixioom-grey-text: #828282;
  --color-aixioom-white: #FFFFFF;
  --color-aixioom-black: #000000;
  --color-aixioom-linear-gradient: linear-gradient(90deg, var(--color-aixioom-blue-4) 0%, var(--color-aixioom-green-main) 100%);

  /* ______Screen size breakpoints_________ */
  /* ______________small__________________ */
  --max-width-aixioom-small-screen: 550px;
  /* ______________medium__________________ */
  --max-width-aixioom-medium-screen: 1500px;
  /* ______________large___________________ */
  --min-width-aixioom-large-screen: 1501px;


  /* _______________Gaps____________________ */
  /* ______________small__________________ */
  --gap-aixioom-small-main: 0px;
  --gap-aixioom-small-element: 12px;
  --gap-aixioom-small-element-big: 20px;

  /* ______________medium__________________ */
  --gap-aixioom-medium-main: 80px;
  --gap-aixioom-medium-element: 24px;
  --gap-aixioom-medium-element-big: 32px;

  /* ______________large___________________ */
  --gap-aixioom-large-main: 80px;
  --gap-aixioom-large-element: 32px;
  --gap-aixioom-large-element-big: 40px;

  /* ______________Padding____________________ */
  /* ______________small__________________ */
  --padding-aixioom-small-main: 15px;

  /* ______________medium__________________ */
  --padding-aixioom-medium-main: 80px;

  /* ______________large___________________ */
  --padding-aixioom-large-main: 250px;


  /* _____________Icon Size____________________ */
  /* ______________small__________________ */
  --height-aixioom-small-icon: 50px;

  /* ______________medium__________________ */
  --height-aixioom-medium-icon: 70px;

  /* ______________large___________________ */
  --height-aixioom-large-icon: 70px;
  
  /* ___________Border Radius________________ */
  /* ______________small__________________ */
  --border-radius-aixioom-small-element: 12px;

  /* ______________medium__________________ */
  --border-radius-aixioom-medium-element: 15px;

  /* ______________large___________________ */
  --border-radius-aixioom-large-element: 15px;


  /* _____________Buttons________________ */
  /* background color hover */
  --background-hover-aixioom-button: var(--color-aixioom-linear-gradient);
  /* justify content */
  --justify-content-aixioom-button: align-center;
  /* align-items */
  --align-items-aixioom-button: center;
  /* display-inline */
  --display-inline-aixioom-button: flex;
  /* gap */
  --gap-aixioom-button: 8px;
  /* background color */
  --background-color-aixioom-button-green: var(--color-aixioom-green-main);
  /* color hover */
  --color-hover-aixioom-button: var(--color-aixioom-white);

  /* grey */
  --box-shadow-aixioom-button-grey: 0px 1px 2px 0px var(--color-aixioom-grey-2-transparent);
  --color-aixioom-button-grey: var(--color-aixioom-blue-main);
  /* green */
  --box-shadow-aixioom-button-green: 0px 1px 2px 0px var(--color-aixioom-grey-2-transparent);
  --color-aixioom-button-green: var(--color-aixioom-blue-white);

  /* hover */
  --box-shadow-hover-aixioom-button: 0px 4px 4px 0px;
  --color-hover-aixioom-button: var(--color-aixioom-white);


  /* ______________small__________________ */
  --padding-aixioom-small-button: 8px 16px;
  --border-radius-aixioom-small-button: 50px;


  /* ______________medium__________________ */
  --padding-aixioom-medium-button: 14px 20px;
  --border-radius-aixioom-medium-button: 50px;

  /* ______________large___________________ */
  --padding-aixioom-large-button: 14px 24px;
  --border-radius-aixioom-large-button: 50px;


  /* ________________Fonts____________________ */
  /* ______________small__________________ */
  /* h1 */
  --font-size-aixioom-small-h1: 30px;
  --font-style-aixioom-small-h1: normal;
  --font-weight-aixioom-small-h1: 700;
  --line-height-aixioom-small-h1: normal;
  --letter-spacing-aixioom-small-h1: -0.6px;
  --margin-aixioom-small-h1: 0px;

  /* h2 */
  --font-size-aixioom-small-h2: 20px;
  --font-style-aixioom-small-h2: normal;
  --font-weight-aixioom-small-h2: 600;
  --line-height-aixioom-small-h2: 110%; /* 22px */
  --margin-aixioom-small-h2: 0px;

  /* h3 */
  --font-size-aixioom-small-h3: 14px;
  --font-style-aixioom-small-h3: normal;
  --font-weight-aixioom-small-h3: 400;
  --line-height-aixioom-small-h3: 150%; /* 21px */
  --margin-aixioom-small-h3: 0px;

  /* Text */
  --font-size-aixioom-small-text: 14px;
  --font-style-aixioom-small-text: normal;
  --font-weight-aixioom-small-text: 400;
  --line-height-aixioom-small-text: 150%; /* 21px */

  /* text footer */ 
  --font-size-aixioom-small-text-footer: 16px;
  --font-style-aixioom-small-text-footer: normal;
  --font-weight-aixioom-small-text-footer: 500;
  --line-height-aixioom-small-text-footer: 150%; /* 24px */

  /* Text small */
  --font-size-aixioom-small-text-small: 12px;
  --font-style-aixioom-small-text-small: normal;
  --font-weight-aixioom-small-text-small: 400;
  --line-height-aixioom-small-text-small: 150%; /* 18px */

  /* Quote */
  --font-size-aixioom-small-quote: 14px;
  --font-style-aixioom-small-quote: italic;
  --font-weight-aixioom-small-quote: 300;
  --line-height-aixioom-small-quote: 150%; /* 21px */

  /* Button text */
  --font-size-aixioom-small-button-text: 12px;
  --font-style-aixioom-small-button-text: normal;
  --font-weight-aixioom-small-button-text: 500;
  --line-height-aixioom-small-button-text: 150%; /* 24px */

  /* Button green text */
  --font-size-aixioom-small-button-green-text: 12px;
  --font-style-aixioom-small-button-green-text: normal;
  --font-weight-aixioom-small-button-green-text: 600;
  --line-height-aixioom-small-button-green-text: 28px; /* 175% */

  /* ______________medium__________________ */
  /* h1 */
  --font-size-aixioom-medium-h1: 44px;
  --font-style-aixioom-medium-h1: normal;
  --font-weight-aixioom-medium-h1: 700;
  --line-height-aixioom-medium-h1: normal;
  --letter-spacing-aixioom-medium-h1: -0.88px;
  --margin-aixioom-medium-h1: 0px;

  /* h2 */
  --font-size-aixioom-medium-h2: 30px;
  --font-style-aixioom-medium-h2: normal;
  --font-weight-aixioom-medium-h2: 600;
  --line-height-aixioom-medium-h2: 110%; /* 33px */
  --margin-aixioom-medium-h2: 0px;

  /* h3 */
  --font-size-aixioom-medium-h3: 16px;
  --font-style-aixioom-medium-h3: normal;
  --font-weight-aixioom-medium-h3: 400;
  --line-height-aixioom-medium-h3: 150%; /* 24px */
  --margin-aixioom-medium-h3: 0px;

  /* text */ 
  --font-size-aixioom-medium-text: 16px;
  --font-style-aixioom-medium-text: normal;
  --font-weight-aixioom-medium-text: 400;
  --line-height-aixioom-medium-text: 150%; /* 24px */

  /* text footer */ 
  --font-size-aixioom-medium-text-footer: 16px;
  --font-style-aixioom-medium-text-footer: normal;
  --font-weight-aixioom-medium-text-footer: 500;
  --line-height-aixioom-medium-text-footer: 150%; /* 24px */

  /* text small */
  --font-size-aixioom-medium-text-small: 14px;
  --font-style-aixioom-medium-text-small: normal;
  --font-weight-aixioom-medium-text-small: 400;
  --line-height-aixioom-medium-text-small: 150%; /* 21px */

  /* Quote */
  --font-size-aixioom-medium-quote: 16px;
  --font-style-aixioom-medium-quote: italic;
  --font-weight-aixioom-medium-quote: 300;
  --line-height-aixioom-medium-quote: 150%; /* 24px */

  /* Button text */
  --font-size-aixioom-medium-button-text: 14px;
  --font-style-aixioom-medium-button-text: normal;
  --font-weight-aixioom-medium-button-text: 500;
  --line-height-aixioom-medium-button-text: 150%; /* 21px */

  /* Button green text */
  --font-size-aixioom-medium-button-green-text: 14px;
  --font-style-aixioom-medium-button-green-text: normal;
  --font-weight-aixioom-medium-button-green-text: 600;
  --line-height-aixioom-medium-button-green-text: 28px; /* 200% */

  /* __________________________large_____________________________ */
  /* h1 */
  --font-size-aixioom-large-h1: 64px;
  --font-style-aixioom-large-h1: normal;
  --font-weight-aixioom-large-h1: 700;
  --line-height-aixioom-large-h1: normal;
  --letter-spacing-aixioom-large-h1: -1.28px;
  --margin-aixioom-large-h1: 0px;

  /* h2 */
  --font-size-aixioom-large-h2: 40px;
  --font-style-aixioom-large-h2: normal;
  --font-weight-aixioom-large-h2: 600;
  --line-height-aixioom-large-h2: 110%; /* 44px */
  --letter-spacing-aixioom-large-h2: -1.28px;
  --margin-aixioom-large-h2: 0px;

  /* h3 */
  --font-size-aixioom-large-h3: 20px;
  --font-style-aixioom-large-h3: normal;
  --font-weight-aixioom-large-h3: 400;
  --line-height-aixioom-large-h3: 150%; /* 30px */
  --margin-aixioom-large-h3: 0px;

  /* text */ 
  --font-size-aixioom-large-text: 18px;
  --font-style-aixioom-large-text: normal;
  --font-weight-aixioom-large-text: 400;
  --line-height-aixioom-large-text: 150%;

  /* text footer */ 
  --font-size-aixioom-large-text-footer: 16px;
  --font-style-aixioom-large-text-footer: normal;
  --font-weight-aixioom-large-text-footer: 500;
  --line-height-aixioom-large-text-footer: 150%; /* 24px */

  /* text small */
  --font-size-aixioom-large-text-small: 16px;
  --font-style-aixioom-large-text-small: normal;
  --font-weight-aixioom-large-text-small: 500;
  --line-height-aixioom-large-text-small: 150%; /* 24px */

  /* Quote */
  --font-size-aixioom-large-quote: 20px;
  --font-style-aixioom-large-quote: italic;
  --font-weight-aixioom-large-quote: 300;
  --line-height-aixioom-large-quote: 150%; /* 30px */

  /* Button text */
  --font-size-aixioom-large-button-text: 14px;
  --font-style-aixioom-large-button-text: normal;
  --font-weight-aixioom-large-button-text: 500;
  --line-height-aixioom-large-button-text: 150%; /* 24px */

  /* Button green text */
  --font-size-aixioom-large-button-green-text: 14px;
  --font-style-aixioom-large-button-green-text: normal;
  --font-weight-aixioom-large-button-green-text: 600;
  --ine-height-aixioom-large-button-green-text: 28px; /* 175% */
}

/* ________________Fonts____________________ */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* _________________________________________ */

/* ________________Scrollbar_________________ */
::-webkit-scrollbar {
  width: fit-content;
}
::-webkit-scrollbar-track {
  background: #f0f0;
}
::-webkit-scrollbar-thumb {
  background: #8888;
}
::-webkit-scrollbar-track:hover {
  background: rgba(255, 0, 0, 0);
}
/* _________________________________________ */

/* ____________________Custom styles________________________ */
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Poppins", sans-serif;
}
/* set headline margin */
body h1, h2, h3 {
  margin: 0;
}

/* Changes Text Background Color if selected */
::selection { background: var(--color-aixioom-green-main); }

/* use this class (for parent) to stack items on top of each other */
.stack-parent {
  display: grid;
}
/* for stacking child in grid */
.stack-child {    
  grid-area: 1 / 1 / 2 / 2;
}

/* for each page gap view */
.landingpage-gap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.page-gap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.page-gap-small {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media(min-width: 1501px) {
  .landingpage-gap {
    padding: 170px 0;
    gap: 200px;
  }
  .page-gap {
    padding: 145px 0;
    gap: 200px;
  }
  .page-gap-small {
    gap: 145px;
  }
}
@media(max-width: 1500px) {
  .landingpage-gap {
    padding: 170px 0;
    gap: 200px;
  }
  .page-gap {
    padding: 145px 0;
    gap: 145px;
  }
  .page-gap-small {
    gap: 75px;
  }
}
@media(max-width: 550px) { 
  .landingpage-gap {
    padding: 50px 0;
    gap: 50px;
  }
  .page-gap {
    padding: 50px 0;
    gap: 50px;
  }
  .page-gap-small {
    gap: 25px;
  }
}



