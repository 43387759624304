.Contact .CompHeadingText-Container h2 {
    white-space: break-spaces;
}

@media (min-width: 1501px) {
    .Contact .CompHeadingText-Container p {
        font-size: 28px;
    }
}
@media (max-width: 1500px) {
    .Contact .CompHeadingText-Container p {
        font-size: 22px;
    }
}
@media (max-width: 500px) {
    .Contact .CompHeadingText-Container p {
        font-size: 16px;
    }
}