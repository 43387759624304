.CompLogo-Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--gap-aixioom-medium-element);
    align-self: stretch;
}

.CompLogo-Container img {
    width: 200px;
}

.CompLogo-Container h3 {
    align-self: stretch;
    text-align: center;
    color: var(--color-aixioom-grey-text);
    font-size: var(--font-size-aixioom-medium-h3);
    font-style: var(--font-style-aixioom-medium-h3);
    font-weight: var(--font-weight-aixioom-medium-h3);
    line-height: var(--line-height-aixioom-medium-h3);
    letter-spacing: var(--letter-spacing-aixioom-medium-h3);
    margin: var(--margin-aixioom-medium-h3);
}

@media(min-width: 1501px) {
    .CompLogo-Container {
        gap: var(--gap-aixioom-large-element);
    }
    
    .CompLogo-Container img {
        width: 400px;
    }
    
    .CompLogo-Container h3 {
        font-size: var(--font-size-aixioom-large-h3);
        font-style: var(--font-style-aixioom-large-h3);
        font-weight: var(--font-weight-aixioom-large-h3);
        line-height: var(--line-height-aixioom-large-h3);
        letter-spacing: var(--letter-spacing-aixioom-large-h3);
        margin: var(--margin-aixioom-large-h3);
    }
}
@media(max-width: 1500px) {
    .CompLogo-Container {
        gap: var(--gap-aixioom-medium-element);
    }
    
    .CompLogo-Container img {
        width: 300px;
    }
    
    .CompLogo-Container h3 {
        font-size: var(--font-size-aixioom-medium-h3);
        font-style: var(--font-style-aixioom-medium-h3);
        font-weight: var(--font-weight-aixioom-medium-h3);
        line-height: var(--line-height-aixioom-medium-h3);
        letter-spacing: var(--letter-spacing-aixioom-medium-h3);
        margin: var(--margin-aixioom-medium-h3);
    }
}
@media(max-width: 550px) {
    .CompLogo-Container {
        gap: var(--gap-aixioom-small-element);
    }
    
    .CompLogo-Container img {
        width: 200px;
    }
    
    .CompLogo-Container h3 {
        font-size: var(--font-size-aixioom-small-h3);
        font-style: var(--font-style-aixioom-small-h3);
        font-weight: var(--font-weight-aixioom-small-h3);
        line-height: var(--line-height-aixioom-small-h3);
        letter-spacing: var(--letter-spacing-aixioom-small-h3);
        margin: var(--margin-aixioom-small-h3);
    }
}