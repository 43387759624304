.CompTextButtons-Container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
}

.CompTextButtons-Container h3 {
    text-align: left;
    color: var(--color-aixioom-blue-main);
    align-self: stretch;
}

.CompTextButtons-Container p {
    color: var(--color-aixioom-grey-text);
    align-self: stretch;
}

.CompTextButtons-Container-Buttons {
    display: flex;
    align-self: stretch;
}

@media(min-width: 1501px) {
    .CompTextButtons-Container {
        gap: 0px;
        max-width: 450px;
    }
    
    .CompTextButtons-Container h3 {
        font-size: 28px;
        font-style: var(--font-style-aixioom-large-h3);
        font-weight: var(--font-weight-aixioom-large-h3);
        line-height: var(--line-height-aixioom-large-h3);
        letter-spacing: var(--letter-spacing-aixioom-large-h3);
        margin: var(--margin-aixioom-large-h3);
    }
    
    .CompTextButtons-Container p {
        font-size: var(--font-size-aixioom-large-text);
        font-style: var(--font-style-aixioom-large-text);
        font-weight: var(--font-weight-aixioom-large-text);
        line-height: var(--line-height-aixioom-large-text);
    }
    
    .CompTextButtons-Container-Buttons {
        padding: 10px 10px 10px 0;
        gap: 10px;
        align-items: center;
    }
}

@media(max-width: 1500px) {
    .CompTextButtons-Container {
        gap: var(--gap-aixioom-medium-element);
        max-width: 450px;
    }
    
    .CompTextButtons-Container h3 {
        font-size: 24px;
        font-style: var(--font-style-aixioom-medium-h3);
        font-weight: var(--font-weight-aixioom-medium-h3);
        line-height: var(--line-height-aixioom-medium-h3);
        letter-spacing: var(--letter-spacing-aixioom-medium-h3);
        margin: var(--margin-aixioom-medium-h3);
    }
    
    .CompTextButtons-Container p {
        font-size: var(--font-size-aixioom-medium-text);
        font-style: var(--font-style-aixioom-medium-text);
        font-weight: var(--font-weight-aixioom-medium-text);
        line-height: var(--line-height-aixioom-medium-text); /* 24px */
    }
    
    .CompTextButtons-Container-Buttons {
        padding: 10px 10px 10px 0;
        gap: 10px;
        align-items: center;
    }
}

@media(max-width: 550px) {
    .CompTextButtons-Container {
        gap: 0px;
        max-width: 450px;
    }
    
    .CompTextButtons-Container h3 {
        font-size: 18px;
        font-style: var(--font-style-aixioom-small-h3);
        font-weight: var(--font-weight-aixioom-small-h3);
        line-height: var(--line-height-aixioom-small-h3);
        letter-spacing: var(--letter-spacing-aixioom-small-h3);
        margin: var(--margin-aixioom-small-h3);
    }
    
    .CompTextButtons-Container p {
        font-size: var(--font-size-aixioom-small-text);
        font-style: var(--font-style-aixioom-small-text);
        font-weight: var(--font-weight-aixioom-small-text);
        line-height: var(--line-height-aixioom-small-text);
    }
    
    .CompTextButtons-Container-Buttons {
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 10px;
    }
}