.SolutionSOP-Content .CompPictureText-Container-Left img, .SolutionSOP-Content .CompPictureTextLeft-Container-Right img  {
    object-fit: contain;
    object-position: 0 0;
    height: auto;
}
.SolutionSOP-Content-SW-Feature {
    display: grid;
    align-self: stretch;
}

.SolutionSOP-Content .CompHeadingText-Container {
    text-align: center;
}

@media(max-width: 929px) {
    .SolutionSOP-Content .CompPictureText-Container {
        flex-direction: column-reverse;
    }
}