.CompSectorPicture-Container-Text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: var(--color-aixioom-blue-main-transparent);
}

.CompSectorPicture-Container-Text h1 {
    color: var(--color-aixioom-white);
}

.CompSectorPicture-Container-Text h3 {
    color: var(--color-aixioom-white);
}

@media(min-width: 1501px) {
    .CompSectorPicture-Container {
        margin: 0 -250px;
    }
    
    .CompSectorPicture-Container-Text {
        padding: 20px var(--padding-aixioom-large-main);
        gap: var(--gap-aixioom-large-element);
    }
    
    .CompSectorPicture-Container-Text h1 {
        font-size: var(--font-size-aixioom-large-h1);
        font-style: var(--font-style-aixioom-large-h1);
        font-weight: var(--font-weight-aixioom-large-h1);
        line-height: var(--line-height-aixioom-large-h1);
        letter-spacing: var(--letter-spacing-aixioom-large-h1);
        margin: var(--margin-aixioom-large-h1);
    }
    
    .CompSectorPicture-Container-Text h3 {
        font-size: var(--font-size-aixioom-large-h3);
        font-style: var(--font-style-aixioom-large-h3);
        font-weight: var(--font-weight-aixioom-large-h3);
        line-height: var(--line-height-aixioom-large-h3);
        letter-spacing: var(--letter-spacing-aixioom-large-h3);
        margin: var(--margin-aixioom-large-h3);
    }
    
    .CompSectorPicture-Container-Picture img {
        width: 100%;
        height: 100%;
    }
}

@media(max-width: 1500px) {
    .CompSectorPicture-Container {
        margin: 0 -80px;
    }
    
    .CompSectorPicture-Container-Text {
        padding: 20px var(--padding-aixioom-medium-main);
        gap: var(--gap-aixioom-medium-element);
    }
    
    .CompSectorPicture-Container-Text h1 {
        font-size: var(--font-size-aixioom-medium-h1);
        font-style: var(--font-style-aixioom-medium-h1);
        font-weight: var(--font-weight-aixioom-medium-h1);
        line-height: var(--line-height-aixioom-medium-h1);
        letter-spacing: var(--letter-spacing-aixioom-medium-h1);
        margin: var(--margin-aixioom-medium-h1);
    }
    
    .CompSectorPicture-Container-Text h3 {
        font-size: var(--font-size-aixioom-medium-h3);
        font-style: var(--font-style-aixioom-medium-h3);
        font-weight: var(--font-weight-aixioom-medium-h3);
        line-height: var(--line-height-aixioom-medium-h3);
        letter-spacing: var(--letter-spacing-aixioom-medium-h3);
        margin: var(--margin-aixioom-medium-h3);
    }
    
    .CompSectorPicture-Container-Picture img {
        width: 100%;
        height: 100%;
    }
}

@media(max-width: 550px) {
    .CompSectorPicture-Container {
        margin: 0 -15px;
    }
    
    .CompSectorPicture-Container-Text {
        padding: 5px;
        gap: var(--gap-aixioom-small-element);
    }
    
    .CompSectorPicture-Container-Text h1 {
        font-size: 24px; /* changed to fit container and to avoid word break */
        font-style: var(--font-style-aixioom-small-h1);
        font-weight: var(--font-weight-aixioom-small-h1);
        line-height: var(--line-height-aixioom-small-h1);
        letter-spacing: var(--letter-spacing-aixioom-small-h1);
        margin: var(--margin-aixioom-small-h1);
    }
    
    .CompSectorPicture-Container-Text h3 {
        font-size: var(--font-size-aixioom-small-h3);
        font-style: var(--font-style-aixioom-small-h3);
        font-weight: var(--font-weight-aixioom-small-h3);
        line-height: var(--line-height-aixioom-small-h3);
        letter-spacing: var(--letter-spacing-aixioom-small-h3);
        margin: var(--margin-aixioom-small-h3);
    }
    
    .CompSectorPicture-Container-Picture, .CompSectorPicture-Container-Picture img {
        width: 100%;
        height: 100%;
    }
}