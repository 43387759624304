.SolutionForecast-Content .CompPictureText-Container-Left img, .SolutionForecast-Content .CompPictureTextLeft-Container-Right img  {
    object-fit: contain;
    object-position: 0 0;
    height: auto;
}
.SolutionForecast-Content-SW-Feature {
    display: grid;
    align-self: stretch;

}

@media(max-width: 929px) {
    .SolutionForecast-Content .CompPictureText-Container {
        flex-direction: column-reverse;
    }
}