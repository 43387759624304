.CompHeadingTextMail-Container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: stretch;
}

.CompHeadingTextMail-Container h2 {
    color: var(--color-aixioom-blue-main);
    /* tetx wrap */
    white-space: pre-line;
}

.CompHeadingTextMail-Container p {
    align-self: stretch;
    color: var(--color-aixioom-grey-text);
}

.CompHeadingTextMail-Container-Text a {
    text-decoration: none;
    color: var(--color-aixioom-grey-text);
}
.CompHeadingTextMail-Container-Text a:hover {
    color: var(--color-aixioom-green-main);
}

@media(min-width: 1501px) {
    .CompHeadingTextMail-Container {
        gap: var( --gap-aixioom-large-element);
    }
    
    .CompHeadingTextMail-Container h2 {
        font-size: var(--font-size-aixioom-large-h2);
        font-style: var(--font-style-aixioom-large-h2);
        font-weight: var(--font-weight-aixioom-large-h2);
        line-height: var(--line-height-aixioom-large-h2);
        letter-spacing: var(--letter-spacing-aixioom-large-h2);
        margin: var(--margin-aixioom-large-h2);
    }
    
    .CompHeadingTextMail-Container p {
        font-size: 22px;
        font-style: var(--font-style-aixioom-large-text);
        font-weight: var(--font-weight-aixioom-large-text);
        line-height: var(--line-height-aixioom-large-text);
    }
}
@media(max-width: 1500px) {
    .CompHeadingTextMail-Container {
        gap: var( --gap-aixioom-medium-element);
    }
    
    .CompHeadingTextMail-Container h2 {
        font-size: var(--font-size-aixioom-medium-h2);
        font-style: var(--font-style-aixioom-medium-h2);
        font-weight: var(--font-weight-aixioom-medium-h2);
        line-height: var(--line-height-aixioom-medium-h2);
        letter-spacing: var(--letter-spacing-aixioom-medium-h2);
        margin: var(--margin-aixioom-medium-h2);
    }
    
    .CompHeadingTextMail-Container p {
        font-size: 22px;
        font-style: var(--font-style-aixioom-medium-text);
        font-weight: var(--font-weight-aixioom-medium-text);
        line-height: var(--line-height-aixioom-medium-text); /* 24px */
    }
}
@media(max-width: 550px) {
    .CompHeadingTextMail-Container {
        gap: var( --gap-aixioom-small-element);
    }
    
    .CompHeadingTextMail-Container h2 {
        font-size: var(--font-size-aixioom-small-h2);
        font-style: var(--font-style-aixioom-small-h2);
        font-weight: var(--font-weight-aixioom-small-h2);
        line-height: var(--line-height-aixioom-small-h2);
        letter-spacing: var(--letter-spacing-aixioom-small-h2);
        margin: var(--margin-aixioom-small-h2);
    }
    
    .CompHeadingTextMail-Container p {
        margin: 0px;
        font-size: var(--font-size-aixioom-small-text);
        font-style: var(--font-style-aixioom-small-text);
        font-weight: var(--font-weight-aixioom-small-text);
        line-height: var(--line-height-aixioom-small-text);
    }
}