.ButtonGrey-Container {
    text-decoration: none;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    box-shadow: var(--box-shadow-aixioom-button-grey);
    background: var(--color-aixioom-grey-2);
    cursor: pointer;
}
.ButtonGrey-Container:hover {
    box-shadow: var(--box-shadow-aixioom-button-green);
    background: var(--color-aixioom-linear-gradient);
}

.ButtonGrey-Container:hover .ButtonGrey-Container-Text { 
    color: var(--color-hover-aixioom-button);
}

.ButtonGrey-Container-Text {
    color: var(--color-aixioom-button-grey);
}

@media(min-width: 1501px) {
    .ButtonGrey-Container {
        padding: var(--padding-aixioom-large-button);
        gap: var(--gap-aixioom-button);
        border-radius: var(--border-radius-aixioom-large-button);
    }
    
    .ButtonGrey-Container-Text {
        font-size: var(--font-size-aixioom-large-button-text);
    }
}
@media(max-width: 1500px) {
    .ButtonGrey-Container {
        padding: var(--padding-aixioom-medium-button);
        gap: var(--gap-aixioom-button);
        border-radius: var(--border-radius-aixioom-medium-button);
    }
    
    .ButtonGrey-Container-Text {
        font-size: var(--font-size-aixioom-medium-button-text);
    }
}
@media(max-width: 550px) {
    .ButtonGrey-Container {
        padding: var(--padding-aixioom-small-button);
        gap: var(--gap-aixioom-button);
        border-radius: var(--border-radius-aixioom-small-button);
    }
    
    .ButtonGrey-Container-Text {
        font-size: var(--font-size-aixioom-small-button-text);
    }
}