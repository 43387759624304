.Customer-Content-Examples {
    display: grid;
    gap: 50px;
}

@media(min-width: 551px) {
  .Customer-Content .CompPictureTextLeft-Container-Right, .Customer-Content .CompPictureText-Container-Left,
  .Customer-Content .CompPictureTextLeft-Container-Right img, .Customer-Content .CompPictureText-Container-Left img {
    height: 280px;
  }
}
@media(max-width: 550px) { 
  .Customer-Content .CompPictureTextLeft-Container-Right, .Customer-Content .CompPictureText-Container-Left,
  .Customer-Content .CompPictureTextLeft-Container-Right img, .Customer-Content .CompPictureText-Container-Left img {
    height: 180px;
  }
}