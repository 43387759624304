.CompCustomerVoice-Container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: stretch;
}

.CompCustomerVoice-Container h2 {
    color: var(--color-aixioom-blue-main);
}

.CompCustomerVoice-ImgText {
    display: flex;
    align-self: stretch;
}
.CompCustomerVoice-ImgText p {
    color:var(--color-aixioom-grey-text);
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1 0 0;
    align-self: stretch;
}

.CompCustomerVoice-ImgText img {
    border-radius: 800px;
}

@media(min-width: 1501px) {
    .CompCustomerVoice-Container {
        align-items: center;
        padding: 0px;
        gap: var(--gap-aixioom-large-element);
    }
    
    .CompCustomerVoice-Container h2 {
        text-align: center;
        font-size: var(--font-size-aixioom-large-h2);
        font-style: var(--font-style-aixioom-large-h2);
        font-weight: var(--font-weight-aixioom-large-h2);
        line-height: var(--line-height-aixioom-large-h2);
        letter-spacing: var(--letter-spacing-aixioom-large-h2);
        margin: var(--margin-aixioom-large-h2);
    }
    
    .CompCustomerVoice-ImgText {
        align-items: center;
        gap: 43px;
    }
    .CompCustomerVoice-ImgText img {
        height: 149px;
        width: 149px;
    }
    .CompCustomerVoice-ImgText p {
        font-size: var(--font-size-aixioom-large-quote);
        font-style: var(--font-style-aixioom-large-quote);
        font-weight: var(--font-weight-aixioom-large-quote);
        line-height: var(--line-height-aixioom-large-quote);
    }
}
@media(max-width: 1500px) {
    .CompCustomerVoice-Container {
        align-items: center;
        padding: 0px;
        gap: var(--gap-aixioom-medium-element);
    }
    
    .CompCustomerVoice-Container h2 {
        text-align: center;
        font-size: var(--font-size-aixioom-medium-h2);
        font-style: var(--font-style-aixioom-medium-h2);
        font-weight: var(--font-weight-aixioom-medium-h2);
        line-height: var(--line-height-aixioom-medium-h2);
        letter-spacing: var(--letter-spacing-aixioom-medium-h2);
        margin: var(--margin-aixioom-medium-h2);
    }
    
    .CompCustomerVoice-ImgText {
        align-items: center;
        gap: 43px;
    }
    .CompCustomerVoice-ImgText img {
        height: 149px;
        width: 149px;
    }
    .CompCustomerVoice-ImgText p {
        font-size: var(--font-size-aixioom-medium-quote);
        font-style: var(--font-style-aixioom-medium-quote);
        font-weight: var(--font-weight-aixioom-medium-quote);
        line-height: var(--line-height-aixioom-medium-quote);
    }
}

@media(max-width: 550px) {
    .CompCustomerVoice-Container {
        padding: 0px;
        gap: var(--gap-aixioom-small-element);
    }
    
    .CompCustomerVoice-Container h2 {
        font-size: var(--font-size-aixioom-small-h2);
        font-style: var(--font-style-aixioom-small-h2);
        font-weight: var(--font-weight-aixioom-small-h2);
        line-height: var(--line-height-aixioom-small-h2);
        letter-spacing: var(--letter-spacing-aixioom-small-h2);
        margin: var(--margin-aixioom-small-h2);
    }
    
    .CompCustomerVoice-ImgText {
        display: flex;
        flex-direction: column;
        gap: 43px;
        width: 100%;
    }
    .CompCustomerVoice-ImgText img {
        height: 149px;
        width: 149px;
    }
    .CompCustomerVoice-ImgText p {
        font-size: var(--font-size-aixioom-small-quote);
        font-style: var(--font-style-aixioom-small-quote);
        font-weight: var(--font-weight-aixioom-small-quote);
        line-height: var(--line-height-aixioom-small-quote);
    }
}