.ButtonGreen-Container {
    text-decoration: none;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    box-shadow: var(--box-shadow-aixioom-button-grey);
    background: var(--color-aixioom-green-main);
    cursor: pointer;
}
.ButtonGreen-Container:hover {
    box-shadow: var(--box-shadow-aixioom-button-green);
    background: var(--color-aixioom-linear-gradient);
}

.ButtonGreen-Container:hover .ButtonGreen-Container-Text { 
    color: var(--color-hover-aixioom-button);
}

.ButtonGreen-Container-Text {
    color: var(--color-aixioom-white);
}

@media(min-width: 1501px) {
    .ButtonGreen-Container {
        padding: var(--padding-aixioom-large-button);
        gap: var(--gap-aixioom-button);
        border-radius: var(--border-radius-aixioom-large-button);
    }
    
    .ButtonGreen-Container-Text {
        font-size: var(--font-size-aixioom-large-button-text);
    }
}
@media(max-width: 1500px) {
    .ButtonGreen-Container {
        padding: var(--padding-aixioom-medium-button);
        gap: var(--gap-aixioom-button);
        border-radius: var(--border-radius-aixioom-medium-button);
    }
    
    .ButtonGreen-Container-Text {
        font-size: var(--font-size-aixioom-medium-button-text);
    }
}
@media(max-width: 550px) {
    .ButtonGreen-Container {
        padding: var(--padding-aixioom-small-button);
        gap: var(--gap-aixioom-button);
        border-radius: var(--border-radius-aixioom-small-button);
    }
    
    .ButtonGreen-Container-Text {
        font-size: var(--font-size-aixioom-small-button-text);
    }
}