.Sectors-Content {
    display: flex;
    align-self: stretch;
    align-items: center;
}

.Sectors-Content-Links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1 0 0;
    align-self: stretch;
}
.Sectors-Content-Links a {
    text-decoration: none;
    color: var(--color-aixioom-blue-main);
}
.Sectors-Content-Links a:hover {
    color: var(--color-aixioom-green-main);
}

@media(min-width: 1101px) {
    .Sectors-Content-Links {
        align-items: flex-start;
    }
}

@media(max-width: 1100px) {
    .Sectors-Content img {
        display: none;
    }
    .Sectors-Content-Links {
        align-items: center;
    }
}

@media(min-width: 1901px) {
    .Sectors-Content {
        gap: var(--gap-aixioom-large-element-big);
    }

    .Sectors-Content-Links {
        gap: var(--gap-aixioom-large-element);
    }
    .Sectors-Content-Links a {
        font-size: var(--font-size-aixioom-large-text);
        font-style: var(--font-style-aixioom-large-text);
        font-weight: var(--font-weight-aixioom-large-text);
        line-height: var(--line-height-aixioom-large-text);
    }
    .Sectors-Content img {
        border-radius: var(--border-radius-aixioom-large-element);
        height: 600px;
    }
}
@media(max-width: 1900px) {
    .Sectors-Content {
        gap: var(--gap-aixioom-large-element-big);
    }

    .Sectors-Content-Links {
        gap: var(--gap-aixioom-large-element);
    }
    .Sectors-Content-Links a {
        font-size: var(--font-size-aixioom-large-text);
        font-style: var(--font-style-aixioom-large-text);
        font-weight: var(--font-weight-aixioom-large-text);
        line-height: var(--line-height-aixioom-large-text);
    }
    .Sectors-Content img {
        border-radius: var(--border-radius-aixioom-large-element);
        height: 500px;
    }
}
@media(max-width: 1650px) {
    .Sectors-Content {
        gap: var(--gap-aixioom-medium-element-big);
    }

    .Sectors-Content-Links {
        gap: var(--gap-aixioom-medium-element);
    }
    .Sectors-Content-Links a {
        font-size: var(--font-size-aixioom-medium-text);
        font-style: var(--font-style-aixioom-medium-text);
        font-weight: var(--font-weight-aixioom-medium-text);
        line-height: var(--line-height-aixioom-medium-text);
    }
    .Sectors-Content img {
        border-radius: var(--border-radius-aixioom-medium-element);
        height: 400px;
    }
}
@media(max-width: 500px) {
    .Sectors-Content {
        gap: var(--gap-aixioom-small-element-big);
    }
    .Sectors-Content-Links {
        gap: var(--gap-aixioom-small-element);
    }
    .Sectors-Content-Links a {
        font-size: var(--font-size-aixioom-small-text);
        font-style: var(--font-style-aixioom-small-text);
        font-weight: var(--font-weight-aixioom-small-text);
        line-height: var(--line-height-aixioom-small-text);
    }
}