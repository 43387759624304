.Footer-Container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
}

.Footer-Container-Divider {
    align-self: stretch;
}
.Footer-Container-Divider hr {
    color: var(--color-aixioom-grey-1);
}

.Footer-Container-Top {
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    align-self: stretch;
    flex-wrap: wrap;
}

.Footer-Container-Top-Text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: var(--color-aixioom-blue-main);
}

.Footer-Container-Top-Right {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    align-content: flex-start;
    flex: 1 0 0;
    flex-wrap: wrap;
}

.Footer-Container-Top-Right-Column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.Footer-Container-Top-Right-Column-Title {
    align-self: stretch;
    color: var(--color-aixioom-blue-main);
}
.Footer-Container-Top-Right-Column-Text {
    align-self: stretch;
    color: var(--color-aixioom-grey-text);
}
.Footer-Container-Top-Right-Column-Text a {
    text-decoration: none;
    color: var(--color-aixioom-grey-text);
}
.Footer-Container-Top-Right-Column-Text a:hover {
    color: var(--color-aixioom-green-main);
}

.Footer-Container-Bottom {
    display: flex;
    align-items: center;
    align-self: stretch;
}

.Footer-Container-Bottom-LinkedIn {
    cursor: pointer;
}
.Footer-Container-Bottom-LinkedIn>svg>path {
    fill: var(--color-aixioom-grey-text);
}
.Footer-Container-Bottom-LinkedIn:hover svg>path {
    fill: var(--color-aixioom-green-main);
}
@media(max-width: 990px) {
    .Footer-Container-Top {
        flex-direction: column;
    }
    .Footer-Container-Top-Right {
        justify-content: flex-start;
    }
}

@media(min-width: 1501px) {
    .Footer-Container {
        padding: 0px var(--padding-aixioom-medium-main);
        gap: 10px;
    }
    
    .Footer-Container-Top {
        gap: var(--gap-aixioom-large-element-big);
    }
    
    .Footer-Container-Top-Text {
        width: 192px;
        gap: 10px;
        font-size: var(--font-size-aixioom-large-text-footer);
        font-style: var(--font-style-aixioom-large-text-footer);
        font-weight: var(--font-weight-aixioom-large-text-footer);
        line-height: var(--line-height-aixioom-large-text-footer);
    }
    
    .Footer-Container-Top-Right {
        gap: 80px;
    }
    
    .Footer-Container-Top-Right-Column {
        width: 149px;
        gap: 0px;
    }
    .Footer-Container-Top-Right-Column-Title {
        font-size: var(--font-size-aixioom-large-text-footer);
        font-style: var(--font-style-aixioom-large-text-footer);
        font-weight: var(--font-weight-aixioom-large-text-footer);
        line-height: var(--line-height-aixioom-large-text-footer);
    }
    .Footer-Container-Top-Right-Column-Text {
        font-size: var(--font-size-aixioom-large-text-footer);
        font-style: var(--font-style-aixioom-large-text-footer);
        font-weight: var(--font-weight-aixioom-large-text-footer);
        line-height: var(--line-height-aixioom-large-text-footer);
    }
    
    .Footer-Container-Bottom {
        padding: 10px 0px;
        gap: 8px;
    }
}
@media(max-width: 1500px) {
    .Footer-Container {
        padding: 0px var(--padding-aixioom-medium-main);
        gap: 10px;
    }
    
    .Footer-Container-Top {
        gap: var(--gap-aixioom-medium-element-big);
    }
    
    .Footer-Container-Top-Text {
        width: 192px;
        gap: 10px;
        font-size: var(--font-size-aixioom-medium-text-footer);
        font-style: var(--font-style-aixioom-medium-text-footer);
        font-weight: var(--font-weight-aixioom-medium-text-footer);
        line-height: var(--line-height-aixioom-medium-text-footer); /* 24px */
    }
    
    .Footer-Container-Top-Right {
        gap: 80px;
    }
    
    .Footer-Container-Top-Right-Column {
        width: 149px;
        gap: 0px;
    }
    .Footer-Container-Top-Right-Column-Title {
        font-size: var(--font-size-aixioom-medium-text-footer);
        font-style: var(--font-style-aixioom-medium-text-footer);
        font-weight: var(--font-weight-aixioom-medium-text-footer);
        line-height: var(--line-height-aixioom-medium-text-footer); /* 24px */
    }
    .Footer-Container-Top-Right-Column-Text {
        font-size: var(--font-size-aixioom-medium-text-footer);
        font-style: var(--font-style-aixioom-medium-text-footer);
        font-weight: var(--font-weight-aixioom-medium-text-footer);
        line-height: var(--line-height-aixioom-medium-text-footer); /* 24px */
    }
    
    .Footer-Container-Bottom {
        padding: 10px 0px;
        gap: 8px;
    }
}
@media(max-width: 550px) {
    .Footer-Container {
        padding: 0px var(--padding-aixioom-small-main);
        gap: 10px;
    }
    
    .Footer-Container-Top {
        gap: var(--gap-aixioom-small-element-big);
    }
    
    .Footer-Container-Top-Text {
        width: 100%;
        gap: 10px;
        font-size: var(--font-size-aixioom-small-text-footer);
        font-style: var(--font-style-aixioom-small-text-footer);
        font-weight: var(--font-weight-aixioom-small-text-footer);
        line-height: var(--line-height-aixioom-small-text-footer); /* 24px */
    }
    
    .Footer-Container-Top-Right {
        gap: 40px;
    }
    
    .Footer-Container-Top-Right-Column {
        width: 100%;
        gap: 0px;
    }
    .Footer-Container-Top-Right-Column p {
        margin: 0px;
    }

    .Footer-Container-Top-Right-Column-Title {
        font-size: var(--font-size-aixioom-small-text-footer);
        font-style: var(--font-style-aixioom-small-text-footer);
        font-weight: var(--font-weight-aixioom-small-text-footer);
        line-height: var(--line-height-aixioom-small-text-footer); /* 24px */
    }
    .Footer-Container-Top-Right-Column-Text {
        font-size: var(--font-size-aixioom-small-text-footer);
        font-style: var(--font-style-aixioom-small-text-footer);
        font-weight: var(--font-weight-aixioom-small-text-footer);
        line-height: var(--line-height-aixioom-small-text-footer); /* 24px */
    }
    
    .Footer-Container-Bottom {
        padding: 10px 0px;
        gap: 8px;
    }
}