.Career .CompHeadingText-Container h2 {
    white-space: break-spaces;
}

.Career-Accordion p {
    font-family: "Poppins", sans-serif;
    font-style: var(--font-style-aixioom-large-text);
    font-weight: var(--font-weight-aixioom-large-text);
    line-height: var(--line-height-aixioom-large-text);
}

@media (min-width: 1501px) {
    .Career .CompHeadingText-Container p {
        font-size: var(--font-size-aixioom-large-text);
    }
    .Career-Accordion p {
        font-size: var(--font-size-aixioom-medium-text);
    }
}
@media (max-width: 1500px) {
    .Career .CompHeadingText-Container p {
        font-size: var(--font-size-aixioom-medium-text);
    }
    .Career-Accordion p {
        font-size: var(--font-size-aixioom-medium-text);
    }
}
@media (max-width: 500px) {
    .Career .CompHeadingText-Container {
        margin-bottom: 50px;
      }
    .Career .CompHeadingText-Container p {
        font-size: var(--font-size-aixioom-small-text);
    }
    .Career-Accordion p {
        font-size: var(--font-size-aixioom-small-text);
    }
}