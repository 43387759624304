.Header {
    width: 100%;
    background-color: var(--color-aixioom-white);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    /* make it sticky */
    position: fixed;
    margin-top: 0px;
    top: -1px;
    z-index: 5;
}

.Navbar {
    padding: 0px var(--padding-aixioom-medium-main);
    min-height: 100px;
    font-size: 20px;
    display: flex;
    align-items: center;
    flex: 1 0 0;
    align-self: stretch;
    justify-content: flex-end;
}

.Navbar-Logo img { 
    height: 40px;
}

.Navbar-Menu-Horizontal, .Navbar-Menu-Vertical {
    list-style: none;
    gap: 45px 22px;
}

.Navbar-Menu-Burger-Open, .Navbar-Menu-Burger-Closed {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    align-self: stretch;
    cursor: pointer;
    /* align icon next to logo */
    align-self: flex-start;
    margin-top: 38px;
}

.Navbar-Logo {
    cursor: pointer;
}

@media(min-width: 1110px) {
    .Navbar-Logo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        align-self: stretch;
        flex: 1 0 0;
    }
    .Navbar-Menu-Horizontal, .Navbar-Menu-Vertical {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        align-content: center;
        gap: 45px 22px;
        align-self: stretch;
        flex-wrap: wrap;
        cursor: pointer;
    }
    .Navbar-Menu-Burger-Open, .Navbar-Menu-Burger-Closed, .Navbar-Search {
        display: none;
    }
    .Navbar-Menu-Horizontal li:hover, .Navbar-Menu-Vertical li:hover {
        border-color: var(--color-aixioom-green-main);
    }
    .activeMenuItem {
        border-color: var(--color-aixioom-green-main) !important;
        border-bottom-style: solid;
        border-bottom-width: 1px;
    }
}

.Navbar-Menu-Horizontal li, .Navbar-Menu-Vertical li{
    border-color: transparent;
    border-bottom-style: solid;
    border-bottom-width: 1px;
}

.Navbar-Menu-Horizontal li a, .Navbar-Menu-Vertical li a {
    text-decoration: none;
    color: var(--color-aixioom-blue-main);
}


@media(max-width: 1109px) {
    .Navbar {
        gap: 0px;
        padding: 0px var(--padding-aixioom-small-main);
        justify-content: center;
    } 
    .Navbar-Logo {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 27px;
    }
    .Navbar-Search {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1 0 0;
        align-self: stretch;
        cursor: pointer;
        /* align icon next to logo */
        align-self: flex-start;
        margin-top: 38px;
    }
    .Navbar-Menu-Horizontal {
        display: none;
    }
    .Navbar-Menu-Vertical {
        display: flex;
        flex-direction: column;
        align-content: center;
        height: 380px;
        justify-content: center;
        gap: 10px;
        padding-left: 0px;
        margin-bottom: 0;
    }
    .Navbar-Menu-Vertical .MenuItems, .Navbar-Menu-Vertical .activeMenuItem {
        text-align: center;
    }
    .Navbar-Menu-Horizontal li:hover a, .Navbar-Menu-Vertical li:hover a {
        border-color: var(--color-aixioom-green-main);
        border-bottom-style: solid;
        border-bottom-width: 1px;
    }
    .activeMenuItem a {
        border-color: var(--color-aixioom-green-main) !important;
        border-bottom-style: solid;
        border-bottom-width: 1px;
    }
}


