.CompTwoPictures-Container {
    display: flex;
    align-items: flex-start;
    align-self: stretch;
}

@media(min-width: 1501px) {
    .CompTwoPictures-Container {
        gap: var(--gap-aixioom-large-element-big);
    }
    .CompTwoPictures-Container img {
        width: 50%;
        border-radius: var(--border-radius-aixioom-large-element);
    }
}
@media(max-width: 1500px) {
    .CompTwoPictures-Container {
        gap: var(--gap-aixioom-medium-element-big);
    }
    .CompTwoPictures-Container img {
        width: 50%;
        border-radius: var(--border-radius-aixioom-medium-element);
    }
}
@media(max-width: 550px) {
    .CompTwoPictures-Container {
        gap: var(--gap-aixioom-small-element-big);
    }
    .CompTwoPictures-Container img {
        width: 100%;
        border-radius: var(--border-radius-aixioom-small-element);
    }
    .CompTwoPictures-Container-Right {
        display: none;
    }
}