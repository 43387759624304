.CompPictureText-Container {
    display: flex;
    align-self: stretch;
    flex: 1 0 0;
    flex-wrap: wrap;
}
.CompPictureText-Container-Left {
    height: 287px;
    width: 448px;
}
.CompPictureText-Container-Left img {
    border-radius: 15px;
    object-fit: cover;
    object-position: 10% 100%;
    flex-shrink: 0;
}

.CompPictureText-Container-Right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex: 1 0 0;
}
.CompPictureText-Container-Right-Title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
}

.CompPictureText-Container-Right-Title h2 {
    color: var(--color-aixioom-blue-main);
}

.CompPictureText-Container-Right-Title h3 {
    color: var(--color-aixioom-blue-main);
}

.CompPictureText-Container-Right-Text p {
    color: var(--color-aixioom-grey-text);
    align-self: stretch;
    /* \n will be recognized as next line as intendet */
    white-space: pre-line;
}

@media(min-width: 1501px) {
    .CompPictureText-Container {
        gap: 75px;
    }
    .CompPictureText-Container-Left {
        width: 448px;
    }
    .CompPictureText-Container-Left img {
        border-radius: var(--border-radius-aixioom-large-element);
        width: 448px;
    }
    
    .CompPictureText-Container-Right {
        gap: var(--gap-aixioom-large-element);
    }
    
    .CompPictureText-Container-Right-Title h2 {
        font-size: var(--font-size-aixioom-large-h2);
        font-style: var(--font-style-aixioom-large-h2);
        font-weight: var(--font-weight-aixioom-large-h2);
        line-height: var(--line-height-aixioom-large-h2);
        letter-spacing: var(--letter-spacing-aixioom-large-h2);
        margin: var(--margin-aixioom-large-h2);
    }
    
    .CompPictureText-Container-Right-Title h3 {
        font-size: var(--font-size-aixioom-large-h3);
        font-style: var(--font-style-aixioom-large-h3);
        font-weight: var(--font-weight-aixioom-large-h3);
        line-height: var(--line-height-aixioom-large-h3);
        letter-spacing: var(--letter-spacing-aixioom-large-h3);
        margin: var(--margin-aixioom-large-h3);
    }
    
    .CompPictureText-Container-Right-Text p {
        font-size: var(--font-size-aixioom-large-text);
        font-style: var(--font-style-aixioom-large-text);
        font-weight: var(--font-weight-aixioom-large-text);
        line-height: var(--line-height-aixioom-large-text);
    }
}
@media(max-width: 1500px) {
    .CompPictureText-Container {
        gap: 25px;
    }
    .CompPictureText-Container-Left {
        width: 448px;
    }
    .CompPictureText-Container-Left img {
        border-radius: var(--border-radius-aixioom-medium-element);
        width: 448px;
    }
    
    .CompPictureText-Container-Right {
        gap: var(--gap-aixioom-medium-element);
    }
    
    .CompPictureText-Container-Right-Title h2 {
        font-size: var(--font-size-aixioom-medium-h2);
        font-style: var(--font-style-aixioom-medium-h2);
        font-weight: var(--font-weight-aixioom-medium-h2);
        line-height: var(--line-height-aixioom-medium-h2);
        letter-spacing: var(--letter-spacing-aixioom-medium-h2);
        margin: var(--margin-aixioom-medium-h2);
    }
    
    .CompPictureText-Container-Right-Title h3 {
        font-size: var(--font-size-aixioom-medium-h3);
        font-style: var(--font-style-aixioom-medium-h3);
        font-weight: var(--font-weight-aixioom-medium-h3);
        line-height: var(--line-height-aixioom-medium-h3);
        letter-spacing: var(--letter-spacing-aixioom-medium-h3);
        margin: var(--margin-aixioom-medium-h3);
    }
    
    .CompPictureText-Container-Right-Text p {
        font-size: var(--font-size-aixioom-medium-text);
        font-style: var(--font-style-aixioom-medium-text);
        font-weight: var(--font-weight-aixioom-medium-text);
        line-height: var(--line-height-aixioom-medium-text); /* 24px */
    }
}
@media(max-width: 916px) {
    .CompPictureText-Container {
        flex-direction: column-reverse;
    }
}

@media(max-width: 550px) {
    .CompPictureText-Container {
        gap: 0px;
    }
    .CompPictureText-Container-Left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1 0;
        align-self: stretch;
        width: 100%;
    }
    .CompPictureText-Container-Left img {
        border-radius: var(--border-radius-aixioom-small-element);
        width: 100%;
        height: 186px;
    }
    
    .CompPictureText-Container-Right {
        gap: 0px;
    }
    
    .CompPictureText-Container-Right-Title h2 {
        font-size: var(--font-size-aixioom-small-h2);
        font-style: var(--font-style-aixioom-small-h2);
        font-weight: var(--font-weight-aixioom-small-h2);
        line-height: var(--line-height-aixioom-small-h2);
        letter-spacing: var(--letter-spacing-aixioom-small-h2);
        margin: var(--margin-aixioom-small-h2);
    }
    
    .CompPictureText-Container-Right-Title h3 {
        font-size: var(--font-size-aixioom-small-h3);
        font-style: var(--font-style-aixioom-small-h3);
        font-weight: var(--font-weight-aixioom-small-h3);
        line-height: var(--line-height-aixioom-small-h3);
        letter-spacing: var(--letter-spacing-aixioom-small-h3);
        margin: var(--margin-aixioom-small-h3);
    }
    
    .CompPictureText-Container-Right-Text p {
        font-size: var(--font-size-aixioom-small-text);
        font-style: var(--font-style-aixioom-small-text);
        font-weight: var(--font-weight-aixioom-small-text);
        line-height: var(--line-height-aixioom-small-text);
    }
}