.App {
  width: 100%;
}

.Content {
  margin-top: 100px;
  display: flex;
}

.Pages {
  width: 100%;
  height: 100%;
}
@media(min-width: 1501px) {
  .Pages {
    padding: 0px var(--padding-aixioom-large-main);
  }
}
@media(max-width: 1500px) {
  .Pages {
    padding: 0px var(--padding-aixioom-medium-main);
  }
}
@media(max-width: 550px) {
  .Pages {
    padding: 0px var(--padding-aixioom-small-main);
  }
}

.CookieConsent {
  background: var(--color-aixioom-blue-main) !important;
}
.CookieConsent Button {
  background: var(--color-aixioom-linear-gradient) !important;
  border-radius: var(--border-radius-aixioom-medium-button) !important;
}