.TeaserSectors-Container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
}
.TeaserSectors-Container h2 {
    text-align: center;
    color: var(--color-aixioom-blue-main);
}
.TeaserSectors-Container h3 {
    text-align: center;
    color: var(--color-aixioom-grey-text);
}

.TeaserSectors-Container-Icons {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    align-self: stretch;
    flex-wrap: wrap;
}

.TeaserSectors-Icon {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.TeaserSectors-Icon h3 {
    text-align: center;
    color: var(--color-aixioom-blue-main);
}

@media(min-width: 1501px) {
    .TeaserSectors-Container {
        padding-bottom: 45px;
        gap: var(--gap-aixioom-large-element-big);
    }
    .TeaserSectors-Container h2 {
        font-size: var(--font-size-aixioom-large-h2);
        font-style: var(--font-style-aixioom-large-h2);
        font-weight: var(--font-weight-aixioom-large-h2);
        line-height: var(--line-height-aixioom-large-h2); /* 33px */
        margin: var(--margin-aixioom-large-h2);
    }
    .TeaserSectors-Container h3 {
        font-size: var(--font-size-aixioom-large-h3);
        font-style: var(--font-style-aixioom-large-h3);
        font-weight: var(--font-weight-aixioom-large-h3);
        line-height: var(--line-height-aixioom-large-h3);
        letter-spacing: var(--letter-spacing-aixioom-large-h3);
        margin: var(--margin-aixioom-large-h3);
    }
    
    .TeaserSectors-Container-Icons {
        padding-top: 32px;
        gap: 62px 32px;
    }
    
    .TeaserSectors-Icon {
        width: 192px;
        gap: var(--gap-aixioom-large-element);
    }
    .TeaserSectors-Icon h3 {
        font-size: var(--font-size-aixioom-large-h3);
        font-style: var(--font-style-aixioom-large-h3);
        font-weight: var(--font-weight-aixioom-large-h3);
        line-height: var(--line-height-aixioom-large-h3);
        letter-spacing: var(--letter-spacing-aixioom-large-h3);
        margin: var(--margin-aixioom-large-h3);
    }
    .TeaserSectors-Icon img {
        height: 50px;
        padding-top: 10px;
        padding-bottom: 10px;
    }
}
@media(max-width: 1500px) {
    .TeaserSectors-Container {
        padding-bottom: 45px;
        gap: var(--gap-aixioom-medium-element-big);
    }
    .TeaserSectors-Container h2 {
        font-size: var(--font-size-aixioom-medium-h2);
        font-style: var(--font-style-aixioom-medium-h2);
        font-weight: var(--font-weight-aixioom-medium-h2);
        line-height: var(--line-height-aixioom-medium-h2); /* 33px */
        margin: var(--margin-aixioom-medium-h2);
    }
    .TeaserSectors-Container h3 {
        font-size: var(--font-size-aixioom-medium-h3);
        font-style: var(--font-style-aixioom-medium-h3);
        font-weight: var(--font-weight-aixioom-medium-h3);
        line-height: var(--line-height-aixioom-medium-h3);
        letter-spacing: var(--letter-spacing-aixioom-medium-h3);
        margin: var(--margin-aixioom-medium-h3);
    }
    
    .TeaserSectors-Container-Icons {
        padding-top: 32px;
        gap: 62px 32px;
    }
    
    .TeaserSectors-Icon {
        width: 192px;
        gap: var(--gap-aixioom-medium-element);
    }
    .TeaserSectors-Icon h3 {
        font-size: var(--font-size-aixioom-medium-h3);
        font-style: var(--font-style-aixioom-medium-h3);
        font-weight: var(--font-weight-aixioom-medium-h3);
        line-height: var(--line-height-aixioom-medium-h3);
        letter-spacing: var(--letter-spacing-aixioom-medium-h3);
        margin: var(--margin-aixioom-medium-h3);
    }
    .TeaserSectors-Icon img {
        height: 50px;
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

@media(max-width: 550px) {
    .TeaserSectors-Container {
        padding-bottom: 45px;
        gap: var(--gap-aixioom-small-element-big);
    }
    .TeaserSectors-Container h2 {
        font-size: var(--font-size-aixioom-small-h2);
        font-style: var(--font-style-aixioom-small-h2);
        font-weight: var(--font-weight-aixioom-small-h2);
        line-height: var(--line-height-aixioom-small-h2);
        margin: var(--margin-aixioom-small-h2);
    }
    .TeaserSectors-Container h3 {
        font-size: var(--font-size-aixioom-small-h3);
        font-style: var(--font-style-aixioom-small-h3);
        font-weight: var(--font-weight-aixioom-small-h3);
        line-height: var(--line-height-aixioom-small-h3);
        letter-spacing: var(--letter-spacing-aixioom-small-h3);
        margin: var(--margin-aixioom-small-h3);
    }
    
    .TeaserSectors-Container-Icons {
        padding-top: 32px;
        gap: 62px 32px;
    }
    
    .TeaserSectors-Icon {
        width: 192px;
        gap: var(--gap-aixioom-small-element);
    }
    .TeaserSectors-Icon h3 {
        font-size: var(--font-size-aixioom-small-h3);
        font-style: var(--font-style-aixioom-small-h3);
        font-weight: var(--font-weight-aixioom-small-h3);
        line-height: var(--line-height-aixioom-small-h3);
        letter-spacing: var(--letter-spacing-aixioom-small-h3);
        margin: var(--margin-aixioom-small-h3);
    }
    .TeaserSectors-Icon img {
        height: 50px;
        padding-top: 10px;
        padding-bottom: 10px;
    }
}