.TeaserPlattform-Container {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    align-self: stretch;
    flex-wrap: wrap;
}

.TeaserPlattform-Container-Left {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 0 0;
}

.TeaserPlattform-Container-Left h1 {
    text-align: center;
    color: var(--color-aixioom-blue-main);
}

.TeaserPlattform-Container-Left h3 {
    text-align: center;
    color: var(--color-aixioom-grey-text);
}

.TeaserPlattform-Container-Right {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex: 1 0 0;
    flex-wrap: wrap;
}

@media(min-width: 1501px) {
    .TeaserPlattform-Container {
        padding-bottom: 52px;
    }
    
    .TeaserPlattform-Container-Left {
        padding: 20px;
        gap: 20px;
    }
    
    .TeaserPlattform-Container-Left h1 {
        font-size: var(--font-size-aixioom-large-h1);
        font-style: var(--font-style-aixioom-large-h1);
        font-weight: var(--font-weight-aixioom-large-h1);
        line-height: var(--line-height-aixioom-large-h1);
        letter-spacing: var(--letter-spacing-aixioom-large-h1);
        margin: var(--margin-aixioom-large-h1);
    }
    
    .TeaserPlattform-Container-Left h3 {
        font-size: var(--font-size-aixioom-large-h3);
        font-style: var(--font-style-aixioom-large-h3);
        font-weight: var(--font-weight-aixioom-large-h3);
        line-height: var(--line-height-aixioom-large-h3);
        letter-spacing: var(--letter-spacing-aixioom-large-h3);
        margin: var(--margin-aixioom-large-h3);
    }
    
    .TeaserPlattform-Container-Right {
        padding: 20px 0px;
        gap: 10px;
    }
    .TeaserPlattform-Container-Right img {
        width: 404px;
        height: 295.267px;
    }
}
@media(max-width: 1500px) {
    .TeaserPlattform-Container {
        padding-bottom: 52px;
    }
    
    .TeaserPlattform-Container-Left {
        padding: 20px;
        gap: 20px;
    }
    
    .TeaserPlattform-Container-Left h1 {
        font-size: var(--font-size-aixioom-medium-h1);
        font-style: var(--font-style-aixioom-medium-h1);
        font-weight: var(--font-weight-aixioom-medium-h1);
        line-height: var(--line-height-aixioom-medium-h1);
        letter-spacing: var(--letter-spacing-aixioom-medium-h1);
        margin: var(--margin-aixioom-medium-h1);
    }
    
    .TeaserPlattform-Container-Left h3 {
        font-size: var(--font-size-aixioom-medium-h3);
        font-style: var(--font-style-aixioom-medium-h3);
        font-weight: var(--font-weight-aixioom-medium-h3);
        line-height: var(--line-height-aixioom-medium-h3);
        letter-spacing: var(--letter-spacing-aixioom-medium-h3);
        margin: var(--margin-aixioom-medium-h3);
    }
    
    .TeaserPlattform-Container-Right {
        padding: 20px 0px;
        gap: 10px;
    }
    .TeaserPlattform-Container-Right img {
        width: 404px;
        height: 295.267px;
    }
}
@media(max-width: 550px) {
    .TeaserPlattform-Container {
        padding-bottom: 52px;
        flex-direction: column;
    }
    
    .TeaserPlattform-Container-Left {
        padding: 20px 0px;
        gap: 20px;
    }
    
    .TeaserPlattform-Container-Left h1 {
        font-size: var(--font-size-aixioom-small-h1);
        font-style: var(--font-style-aixioom-small-h1);
        font-weight: var(--font-weight-aixioom-small-h1);
        line-height: var(--line-height-aixioom-small-h1);
        letter-spacing: var(--letter-spacing-aixioom-small-h1);
        margin: var(--margin-aixioom-small-h1);
    }
    
    .TeaserPlattform-Container-Left h3 {
        font-size: var(--font-size-aixioom-small-h3);
        font-style: var(--font-style-aixioom-small-h3);
        font-weight: var(--font-weight-aixioom-small-h3);
        line-height: var(--line-height-aixioom-small-h3);
        letter-spacing: var(--letter-spacing-aixioom-small-h3);
        margin: var(--margin-aixioom-small-h3);
    }
    
    .TeaserPlattform-Container-Right {
        padding: 20px 0px;
        gap: 10px;
        width: 100%;
    }
    .TeaserPlattform-Container-Right img {
        width: 100%
    }
}