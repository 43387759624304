.CompMgmtBoard-Container {
    display: grid;
    align-self: stretch;
    gap: 50px;
}

.CompMgmtBoard-Container-Text h2 {
    color: var(--color-aixioom-blue-main);
}

.CompMgmtBoard-Container-Text p {
    align-self: stretch;
    color: var(--color-aixioom-grey-text);
}

.CompMgmtBoard-Container-Employee {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    align-self: stretch;
    flex-wrap: wrap;
}

@media(min-width: 1501px) {
    .CompMgmtBoard-Container-Text h2 {
        font-size: var(--font-size-aixioom-large-h2);
        font-style: var(--font-style-aixioom-large-h2);
        font-weight: var(--font-weight-aixioom-large-h2);
        line-height: var(--line-height-aixioom-large-h2);
        letter-spacing: var(--letter-spacing-aixioom-large-h2);
        margin: var(--margin-aixioom-large-h2);
    }
    
    .CompMgmtBoard-Container-Text p {
        font-size: var(--font-size-aixioom-large-text);
        font-style: var(--font-style-aixioom-large-text);
        font-weight: var(--font-weight-aixioom-large-text);
        line-height: var(--line-height-aixioom-large-text);
    }
    
    .CompMgmtBoard-Container-Employee {
        padding: 10px 0px;
        gap: 100px;
    }
}
@media(max-width: 1500px) {
    .CompMgmtBoard-Container-Text h2 {
        font-size: var(--font-size-aixioom-medium-h2);
        font-style: var(--font-style-aixioom-medium-h2);
        font-weight: var(--font-weight-aixioom-medium-h2);
        line-height: var(--line-height-aixioom-medium-h2);
        letter-spacing: var(--letter-spacing-aixioom-medium-h2);
        margin: var(--margin-aixioom-medium-h2);
    }
    
    .CompMgmtBoard-Container-Text p {
        font-size: var(--font-size-aixioom-medium-text);
        font-style: var(--font-style-aixioom-medium-text);
        font-weight: var(--font-weight-aixioom-medium-text);
        line-height: var(--line-height-aixioom-medium-text); /* 24px */
    }
    
    .CompMgmtBoard-Container-Employee {
        padding: 10px 0px;
        gap: 100px;
    }
}
@media(max-width: 550px) {
    .CompMgmtBoard-Container-Text h2 {
        font-size: var(--font-size-aixioom-small-h2);
        font-style: var(--font-style-aixioom-small-h2);
        font-weight: var(--font-weight-aixioom-small-h2);
        line-height: var(--line-height-aixioom-small-h2);
        letter-spacing: var(--letter-spacing-aixioom-small-h2);
        margin: var(--margin-aixioom-small-h2);
    }
    
    .CompMgmtBoard-Container-Text p {
        font-size: var(--font-size-aixioom-small-text);
        font-style: var(--font-style-aixioom-small-text);
        font-weight: var(--font-weight-aixioom-small-text);
        line-height: var(--line-height-aixioom-small-text);
    }
    
    .CompMgmtBoard-Container-Employee {
        padding: 10px 0px;
        gap: 50px;
    }
}

.CompMgmtBoard-Container  .CompHeadingText-Container {
    align-items: normal;
}