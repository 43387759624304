.CompLogoText-Container {
    display: flex;
    align-content: center;
    align-self: stretch;
    flex: 1 0 0;
    flex-wrap: wrap;
}
.CompLogoText-Container-Left {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex: 1 0 0;
    flex-wrap: wrap;
}

.CompLogoText-Container-Right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex: 1 0 0;
}
.CompLogoText-Container-Right-Title {
    display: flex;
    flex-direction: column;
    align-self: stretch;
}

.CompLogoText-Container-Right-Title h2 {
    color: var(--color-aixioom-blue-main);
}

.CompLogoText-Container-Right-Title h3 {
    color: var(--color-aixioom-blue-main);
    font-size: var(--font-size-aixioom-medium-h3);
}

.CompLogoText-Container-Right-Text p {
    color: var(--color-aixioom-grey-text);
    align-self: stretch;
}

@media(max-width: 851px) {
    .CompLogoText-Container {
        flex-direction: column-reverse;
    }
    .CompLogoText-Container-Right-Title h2, .CompLogoText-Container-Right-Title h3, .CompLogoText-Container-Right-Text p {
        text-align: center;
    }
}

@media(min-width: 1501px) {
    .CompLogoText-Container {
        gap: 50px;
    }
    .CompLogoText-Container-Left {
        gap: 10px;
        width: 100%;
    }
    .CompLogoText-Container-Left img {
        max-height: 100px;
        width: 60%;
    }
    
    .CompLogoText-Container-Right {
        height: 350px;
        gap: var(--gap-aixioom-large-element);
    }
    
    .CompLogoText-Container-Right-Title h2 {
        font-size: var(--font-size-aixioom-large-h2);
        font-style: var(--font-style-aixioom-large-h2);
        font-weight: var(--font-weight-aixioom-large-h2);
        line-height: var(--line-height-aixioom-large-h2);
        letter-spacing: var(--letter-spacing-aixioom-large-h2);
        margin: var(--margin-aixioom-large-h2);
    }
    
    .CompLogoText-Container-Right-Title h3 {
        font-size: var(--font-size-aixioom-large-h3);
        font-style: var(--font-style-aixioom-large-h3);
        font-weight: var(--font-weight-aixioom-large-h3);
        line-height: var(--line-height-aixioom-large-h3);
        letter-spacing: var(--letter-spacing-aixioom-large-h3);
        margin: var(--margin-aixioom-large-h3);
    }
    
    .CompLogoText-Container-Right-Text p {
        font-size: var(--font-size-aixioom-large-text);
        font-style: var(--font-style-aixioom-large-text);
        font-weight: var(--font-weight-aixioom-large-text);
        line-height: var(--line-height-aixioom-large-text);
    }
}
@media(max-width: 1500px) {
    .CompLogoText-Container {
        gap: 25px;
    }
    .CompLogoText-Container-Left {
        gap: 10px;
        width: 100%;
    }
    .CompLogoText-Container-Left img {
        max-height: 100px;
        width: 60%;
    }
    
    .CompLogoText-Container-Right {
        height: 350px;
        gap: var(--gap-aixioom-medium-element);
    }
    
    .CompLogoText-Container-Right-Title h2 {
        font-size: var(--font-size-aixioom-medium-h2);
        font-style: var(--font-style-aixioom-medium-h2);
        font-weight: var(--font-weight-aixioom-medium-h2);
        line-height: var(--line-height-aixioom-medium-h2);
        letter-spacing: var(--letter-spacing-aixioom-medium-h2);
        margin: var(--margin-aixioom-medium-h2);
    }
    
    .CompLogoText-Container-Right-Title h3 {
        font-size: var(--font-size-aixioom-medium-h3);
        font-style: var(--font-style-aixioom-medium-h3);
        font-weight: var(--font-weight-aixioom-medium-h3);
        line-height: var(--line-height-aixioom-medium-h3);
        letter-spacing: var(--letter-spacing-aixioom-medium-h3);
        margin: var(--margin-aixioom-medium-h3);
    }
    
    .CompLogoText-Container-Right-Text p {
        font-size: var(--font-size-aixioom-medium-text);
        font-style: var(--font-style-aixioom-medium-text);
        font-weight: var(--font-weight-aixioom-medium-text);
        line-height: var(--line-height-aixioom-medium-text);
    }
}
@media(max-width: 550px) {
    .CompLogoText-Container {
        align-items: center;
        gap: 12px;
    }
    .CompLogoText-Container-Left {
        gap: 10px;
        width: 100%;
    }
    .CompLogoText-Container-Left img {
        max-height: 80px;
        width: 60%;
    }
    
    .CompLogoText-Container-Right {
        height: 350px;
        gap: var( --gap-aixioom-small-element);
    }
    
    .CompLogoText-Container-Right-Title h2 {
        font-size: var(--font-size-aixioom-small-h2);
        font-style: var(--font-style-aixioom-small-h2);
        font-weight: var(--font-weight-aixioom-small-h2);
        line-height: var(--line-height-aixioom-small-h2);
        letter-spacing: var(--letter-spacing-aixioom-small-h2);
        margin: var(--margin-aixioom-small-h2);
    }
    
    .CompLogoText-Container-Right-Title h3 {
        font-size: var(--font-size-aixioom-small-h3);
        font-style: var(--font-style-aixioom-small-h3);
        font-weight: var(--font-weight-aixioom-small-h3);
        line-height: var(--line-height-aixioom-small-h3);
        letter-spacing: var(--letter-spacing-aixioom-small-h3);
        margin: var(--margin-aixioom-small-h3);
    }
    
    .CompLogoText-Container-Right-Text p {
        font-size: var(--font-size-aixioom-small-text);
        font-style: var(--font-style-aixioom-small-text);
        font-weight: var(--font-weight-aixioom-small-text);
        line-height: var(--line-height-aixioom-small-text);
    }
}